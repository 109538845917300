body {
  display: flex;
  flex-direction: column;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-x: hidden;
}

.advantage-card{
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(50, 54, 67, 0.25);
  min-height: 100%;
  & .advantage-card-header{
    border-radius: 8px 8px 0 0;
  }
  & img{
    max-height: 200px;
    min-height: 200px;
    //object-fit: cover;
    //object-position: 50% 25%;
  }
  & .text{
      color: #6d7278;;
  }
}

.section-separator{
  width: 486px;
  height: 1px;
  max-width: 90%;
  margin: 50px auto;
  border: solid 1px #bfbebe;
}


.satisfaction,
.youtube-video {
  & .satisfaction-carousel{
    //border-radius: 8px;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    //background-color: rgba(var(--c-main-rgb), 0.38);
    min-height: 200px;
  }
  & .carousel-indicators button{
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.vehicles{
  margin-bottom: 100px;
  & .vehicle-model-img{
    //height: 100px;
    object-fit: contain;
    margin-bottom: 30px;
  }
}

.avatar-img{
  height: 70px;
  width: 70px !important;
  object-fit: cover;
}

// with 3 Items
.multiple-view-carousel:not(.count4) {
  @media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
      display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
      display: block;
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  /* medium and up screens */
  @media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(33%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-33%);
    }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
    transform: translateX(0);
  }
}
// with 4 Items
.multiple-view-carousel.count4 {
  @media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
      display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
      display: block;
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  /* medium and up screens */
  @media (min-width: 768px) {
      .carousel-inner .carousel-item-end.active,
      .carousel-inner .carousel-item-next {
        transform: translateX(25%);
      }

      .carousel-inner .carousel-item-start.active,
      .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
      }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
    transform: translateX(0);
  }
}